import React, { useMemo } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

function filterCourses(semcampID, courses) {
  if (!courses) {
    return [];
  }
  return courses.filter(course => {
    return course.semcamp_id === semcampID;
  });
}

const ApplicationDetails = (props) => {
  const classes = useStyles();
  const { campuses, courses, applicationDetails, setApplicationDetails } = props;
  const filteredCourses = useMemo(() => filterCourses(applicationDetails.campus, courses), [applicationDetails.campus, courses]);
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel shrink id="type">
              Type
            </InputLabel>
            <Select
              labelId="type"
              id="type"
              value={applicationDetails.type}
              onChange={(e) => {
                setApplicationDetails(prevDetails => {
                  return ({
                    ...prevDetails,
                    type: e.target.value
                  });
                })
              }}
              displayEmpty
            >
              <MenuItem disabled value=""><em>Select</em></MenuItem>
              <MenuItem value="FRESHMAN">Freshman</MenuItem>
              <MenuItem value="TRANSFEREE">Transferee</MenuItem>
              <MenuItem value="SHIFTEE">Shiftee</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel shrink id="campus">
              Campus
            </InputLabel>
            <Select
              labelId="campus"
              id="campus"
              value={applicationDetails.campus}
              onChange={(e) => {
                setApplicationDetails(prevDetails => {
                  return ({
                    ...prevDetails,
                    campus: e.target.value,
                    course1: 0,
                    course2: 0,
                    course3: 0,
                  });
                })
              }}
              displayEmpty
            >
              <MenuItem disabled value={0}>
                <em>Select</em>
              </MenuItem>
              {
                campuses && campuses.map(campus => {
                  return (
                    <MenuItem key={campus.id} value={campus.id}>
                      {campus.campus_code}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink id="course">
              First Priority Course
            </InputLabel>
            <Select
              labelId="course"
              id="course"
              value={applicationDetails.course1}
              onChange={(e) => {
                setApplicationDetails(prevDetails => {
                  return {
                    ...prevDetails,
                    course1: e.target.value
                  }
                })
              }}
              displayEmpty
            >
              <MenuItem disabled value={0}>
                <em>Select</em>
              </MenuItem>
              {
                filteredCourses && filteredCourses.map(course1 => {
                  return (
                    <MenuItem key={course1.id} value={course1.id}>
                      {`[${course1.course_code}] ${course1.course_name}`}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink id="course">
              Second Priority Course
            </InputLabel>
            <Select
              labelId="course"
              id="course"
              value={applicationDetails.course2}
              onChange={(e) => {
                setApplicationDetails(prevDetails => {
                  return {
                    ...prevDetails,
                    course2: e.target.value
                  }
                })
              }}
              displayEmpty
            >
              <MenuItem disabled value={0}>
                <em>Select</em>
              </MenuItem>
              {
                filteredCourses && filteredCourses.map(course2 => {
                  return (
                    <MenuItem key={course2.id} value={course2.id}>
                      {`[${course2.course_code}] ${course2.course_name}`}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink id="course">
              Third Priority Course
            </InputLabel>
            <Select
              labelId="course"
              id="course"
              value={applicationDetails.course3}
              onChange={(e) => {
                setApplicationDetails(prevDetails => {
                  return {
                    ...prevDetails,
                    course3: e.target.value
                  }
                })
              }}
              displayEmpty
            >
              <MenuItem disabled value={0}>
                <em>Select</em>
              </MenuItem>
              {
                filteredCourses && filteredCourses.map(course3 => {
                  return (
                    <MenuItem key={course3.id} value={course3.id}>
                      {`[${course3.course_code}] ${course3.course_name}`}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

export default ApplicationDetails;
