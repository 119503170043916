import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Divider from '@material-ui/core/Divider'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },

    infoContent: {
        padding: theme.spacing(8, 0, 7),
    },

    requirementsIcon: {
        color: theme.palette.success.main,
    }
}));

export default function Landing() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return(
        <main>
            <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <Typography component="h1" variant="h2" align="center" color="primary" gutterBottom>
                    BISU Online Entrance Exam Application
                    </Typography>
                    <Typography variant="body1" align="center" color="textPrimary" paragraph>
                    The online application for the Bohol Island State University entrance examination for Calape and Clarin Campus is extended until: <em>Calape Campus, January 31 - Feruary 29, 2024</em>, <em>Clarin Campus, January 31 - February 16, 2024</em>.
                    </Typography>
                    <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                          <Button variant="contained" color="primary" href="/apply">
                              APPLY NOW
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" color="primary" href="/programs">
                              Programs Offered
                          </Button>
                        </Grid>
                    </Grid>
                    {/* <Typography variant="body2" align="center" color="textSecondary">
                        <em><small>Application period is currently close.</small></em>
                    </Typography> */}
                    </div>
                </Container>
            </div>
            <Divider />
            <div className={classes.infoContent}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6" >Requirements</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        <List>
                            <Typography variant="body1" align="left" color="textPrimary" paragraph>
                                <b>Eligibility</b>
                            </Typography>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                    A graduate of Senior High School OR expecting to graduate on S.Y. 2023-2024 from any DepEd accredited High School.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Typography variant="body1" align="left" color="textPrimary" paragraph>
                                <b>Documentary Requirements</b>
                            </Typography>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        A Certification that the applicant is currently enrolled in Senior High School duly signed by the Principal or by an authorized personnel
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Certified true copy of Form 137 in Grade 10 and Grade 11
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Certificate of Good Moral Character
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        2x2 Picture, formal, recent, with a nametag and plain background
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Informative copy of Transcript of Records <i>(for Transferees)</i>
                                        <br />
                                        <small>In case a student has been enrolled in other HEI and wants to transfer to BISU but has dropped or withdrawn all subjects, a certified true copy of Grade 11 and 12 will be evaluated. They can only apply in the Non-Board courses.</small>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Certificate of General Weighted Average <i>(for Transferees)</i>
                                        <br />
                                        <small>Note: The grades in Grade 10 and Grade 11 are evaluated against the grade requirement for the curricular program applied for by the applicants for Admission purposes ONLY.</small>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Typography variant="body1" align="left" color="textPrimary" paragraph>
                                <b>Additional Data</b> <small>(IF APPLICABLE)</small>
                            </Typography>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                    Indigenous People – Certificate of Tribe Membership, or any equivalent certification, as belonging to indigenous people/indigenous cultural community
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                    PWD – Disability Certificate from DSWD or PWD Identification Card
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6" >Qualifications</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        <List>
                            <Typography variant="body1" align="left" color="textPrimary" paragraph>
                                <b>Teacher Education</b>
                            </Typography>
                            <ListItem>
                            <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                    <b>SHS Graduates:</b> The average grade is at least 85 or 2.0 for transferees (no rounding off). There should be no grade below 80 in all subjects (in all terms) of the last semester attended.
                                    <br />
                                    <b>Transferees:</b> The average is at least of 2.0 (no rounding off) for the last semester attended with no grade below 2.5 in all subjects of the last semester attended.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Typography variant="body1" align="left" color="textPrimary" paragraph>
                                <b>Engineering</b>
                            </Typography>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                    <b>Non-Stem:</b> The average grade is at least 85, with no grade below 80 in all subjects in the final rating.
                                    <br />
                                    <b>Stem:</b> The average grade is at least 80, with no grade below 80 for Mathematics and Science in the final rating.
                                    <br />
                                    <b>Transferees:</b> The average grade is at least 2.5 at the last semester attended.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Typography variant="body1" align="left" color="textPrimary" paragraph>
                                <b>Other Board Programs</b>
                            </Typography>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                    <b>SHS Graduates:</b> The average grade is at least 80, with no grade below 80 in all subjects in the final rating.
                                    <br />
                                    <b>Transferees:</b> The average grade is at least 2.5 in the last semester attended with no grade below 2.5 in all subjects in the final rating.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Typography variant="body1" align="left" color="textPrimary" paragraph>
                                <b>Other Board Programs</b>
                            </Typography>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                    <b>SHS Graduates:</b> The average grade is at least 80 in the final rating.
                                    <br />
                                    <b>Transferees:</b> The average grade is at least 2.5 in the last semester attended, with no grade below 3.0 in all subjects in the final rating.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Typography variant="body1" align="left" color="textPrimary" paragraph>
                                <b>Masters and Doctorate Programs</b>
                            </Typography>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                    The average rating is at least 2.5 of the program graduated.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6" >Application Procedure</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Click on the <b>Apply Now</b> button.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Fill in the application form completely.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Upload clear scanned copies of all the required documents.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Review that all the information you provided are correct,
                                        and all required documents uploaded are complete.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Click on the <b>Submit</b> button to complete your application.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6" >Application Verification</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemText>
                                    <Typography>
                                        Verfication of your data and documents will be conducted by the assigned
                                        personnel per college. Notice of confirmation and testing permit (with test schedule)
                                        will be sent to the email address you've provided upon approval of your application.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText>
                                    <Typography>
                                        Application with incomplete requirements cannot proceed to the next step of the application process.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography>
                                        Any false or tampered information of the required documents uploaded in the online application will
                                        automatically invalidate the application of the student applicant and permanently prohibit him/her
                                        in pursuing to study at Bohol Island State University.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6" >Privacy Statement</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemText>
                                    <Typography align='justify'>
                                    Bohol Island State University (BISU) recognizes its responsibilities under the Republic Act No. 10173 (Act), also known as the Data Privacy Act of 2012, with respect to the data it collect, record, organize, update, use, consolidate or destruct from applicants who will provide their personal information for the Online Admission Application. The personal data obtained from this portal is entered and stored within the authorized information and communications system and will only be accessed by authorized personnel.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography align='justify'>
                                    Furthermore, the information collected and stored in the portal shall only be used to determine the qualification of the applicants to the university's curriculum offerings, and serve as basis for ranking. BISU shall not disclose the participant's personal information without their consent.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography align='justify'>
                                    Proceeding to the application indicates that the participant has read the Data Privacy Statement and expresses consent to collect, record, organize, update or modify, retrieve, consult, use, consolidate, block, erase or destruct my personal data as part of my information.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography align='justify'>
                                    Proceeding hereby also affirms the participant's right to be informed, object to processing, access and rectify, suspend or withdraw his/her personal data, and be indemnified in case of damages pursuant to the provisions of the Republic Act No. 10173 of the Philippines, Data Privacy Act of 2012 and its corresponding Implementing Rules and Regulations.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </div>
        </main>
    );
}
