import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton'

import "../assets/styles/css/styles.css"


const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    infoContent: {
        padding: theme.spacing(8, 0, 7),
    },
    requirementsIcon: {
        color: theme.palette.success.main,
    },
    closebutton:{
      float: 'right',
      color: 'black',
      fontSize: '13px',
      paddingTop: '0px'
    },
    modal: {
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'scroll',
      position:'absolute',
      top:'10%',
      left:'10%',
      height:'100%',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
}));


export default function Programs() {
  const classes = useStyles();
  const [openMain, setOpenMain] = React.useState(false);
  const [openBal, setOpenBal] = React.useState(false);
  const [openBil, setOpenBil] = React.useState(false);
  const [openCal, setOpenCal] = React.useState(false);
  const [openCan, setOpenCan] = React.useState(false);
  const [openCla, setOpenCla] = React.useState(false);

  const handleOpenMain = () => {  setOpenMain(true);  };
  const handleCloseMain = () => { setOpenMain(false);  };
  const handleOpenBal = () => {  setOpenBal(true);  };
  const handleCloseBal = () => { setOpenBal(false);  };
  const handleOpenBil = () => {  setOpenBil(true);  };
  const handleCloseBil = () => { setOpenBil(false);  };
  const handleOpenCal = () => {  setOpenCal(true);  };
  const handleCloseCal = () => { setOpenCal(false);  };
  const handleOpenCan = () => {  setOpenCan(true);  };
  const handleCloseCan = () => { setOpenCan(false);  };
  const handleOpenCla = () => {  setOpenCla(true);  };
  const handleCloseCla = () => { setOpenCla(false);  };


  const buttons = [
    <Button key="main" onClick={handleOpenMain}>Main Campus</Button>,
    <Button key="two" onClick={handleOpenBal}>Balilihan Campus</Button>,
    <Button key="bilar" onClick={handleOpenBil}>Bilar Campus</Button>,
    <Button key="calape" onClick={handleOpenCal}>Calape Campus</Button>,
    <Button key="candijay" onClick={handleOpenCan}>Candijay Campus</Button>,
    <Button key="clarin" onClick={handleOpenCla}>Clarin Campus</Button>,
  ];

  return(
    <main>
        <div className={classes.heroContent}>
            <Container maxWidth="sm">
                <Typography component="h1" variant="h2" align="center" color="Primary" gutterBottom>
                Programs Offerings for A.Y. 2024-2025
                </Typography>
                <Typography variant="body1" align="center" color="textPrimary" paragraph>
                The period for the online filing of entrance exam application of Bohol Island State University for <b>A.Y. 2024-2025</b> will be from <b>November 10 - December 15, 2023</b>.
                </Typography>
                <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                    <Grid item>
                      <Button variant="contained" color="primary" href="/apply">
                          APPLY NOW
                      </Button>
                    </Grid>
                </Grid>
                </div>
            </Container>
        </div>
        <Divider />
        <div className={classes.infoContent}>
          <Grid container spacing={2} justify="center">
              <Grid item>
                <ButtonGroup
                  orientation="vertical"
                  aria-label="vertical contained button group"
                  variant="text"
                >
                  {buttons}
                </ButtonGroup>

              </Grid>
          </Grid>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openMain}
            onClose={handleCloseMain}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openMain}>
              <div className={classes.paper}>
              <IconButton className={classes.closebutton} onClick={handleCloseMain} aria-label="close" color="primary">
                <CloseIcon />
              </IconButton>
                <h1 id="transition-modal-title">Main Campus</h1>
                <h3 id="transition-modal-description">College of Engineering and Architecture</h3>
                  <li>Bachelor of Science in Civil Engineering</li>
                  <li>Bachelor of Science in Computer Engineering</li>
                  <li>Bachelor of Science in Electrical Engineering</li>
                  <li>Bachelor of Science in Mechanical Engineering</li>
                  <li>Bachelor of Science in Architecture</li>
                  <li>Bachelor of Science in Fine Arts major in Industrial Design</li>
                <h3 id="transition-modal-description">College of Teacher Education</h3>
                  <li>Bachelor in Elementary Education</li>
                  <li>Bachelor in Secondary Education major in English</li>
                  <li>Bachelor in Secondary Education major in Filipino</li>
                  <li>Bachelor in Secondary Education major in Mathematics</li>
                  <li>Bachelor in Secondary Education major in Social Studies</li>
                  <li>Bachelor in Technology and Livelihood Education</li>
                  <ul>
                    <li>major in Industrial Technology</li>
                    <li>major in Home Economics</li>
                  </ul>
                  <li>Bachelor in Technical Vocational Teacher Education</li>
                  <ul>
                    <li>major in Beauty Care and Wellness</li>
                    <li>major in Automotive Technology</li>
                    <li>major in Civil and Construction Technology</li>
                    <li>major in Electrical Technology</li>
                    <li>major in Food and Services Management</li>
                    <li>major in Garments and Fashion Design </li>
                    <li>major in Hotel and Restaurant Services Technology</li>
                  </ul>
                <h3 id="transition-modal-description">College of Arts and Sciences</h3>
                  <li>Bachelor of Science in Psychology</li>
                  {/* <li>Bachelor of Science in Mathematics</li>
                  <li>Bachelor in Public Administration</li> */}
                <h3 id="transition-modal-description">College of Business and Management</h3>
                  <li>Bachelor of Science in Entrepreneurship</li>
                  <li>Bachelor of Science in Hospitality Management</li>
                  <li>Bachelor of Science in Tourism Management</li>
                  <li>Bachelor of Science in Office Administration</li>
                <h3 id="transition-modal-description">College of Technology and Allied Sciences</h3>
                  <li>Bachelor of Science in Electrical Technology</li>
                  <li>Bachelor of Science in Electronics Technology</li>
                  <li>Bachelor of Science in Industrial Technology</li>
                  <ul>
                    <li>major in Automotive Technology</li>
                    <li>major in Civil Technology</li>
                    <li>major in Cosmetology Technology</li>
                    <li>major in Drafting Technology</li>
                    <li>major in Food Preparation and Service Mangement</li>
                    <li>major in Garments Technology</li>
                    <li>major in Heating, Ventilating, Air-Conditioning, and Refrigeration Technology</li>
                    <li>major in Mechanical Technology</li>
                    <li>major in Wood Technology</li>
                  </ul>
                  <li>Diploma in Electrical Technology</li>
                  <li>Diploma in Electronics Technology</li>
                  <li>Diploma in Industrial Technology</li>
                  <ul>
                    <li>major in Automotive Technology</li>
                    <li>major in Civil Technology</li>
                    <li>major in Cosmetology Technology</li>
                    <li>major in Drafting Technology</li>
                    <li>major in Food Preparation and Service Mangement</li>
                    <li>major in Garments Technology</li>
                    <li>major in Heating, Ventilating, Air-Conditioning, and Refrigeration Technology</li>
                    <li>major in Mechanical Technology</li>
                    <li>major in Wood Technology</li>
                  </ul>
                <h3 id="transition-modal-description">College of Advance Studies</h3>
                  <li>Doctor of Education major in Educational Management</li>
                  <li>Doctor of Philosophy major in Technology Management</li>
                  <li>Master in Public Administration</li>
                  <li>Master of Science in Mathematics</li>
                  <li>Master of Education major in Educational Management</li>
                  <li>Master of Arts in Teaching Vocational Education</li>
                  <ul>
                    <li>major in Automotive Technology</li>
                    <li>major in Civil Technology</li>
                    <li>major in Drafting Technology</li>
                    <li>major in Electrical Technology</li>
                    <li>major in Electronics Technology</li>
                    <li>major in Food Technology</li>
                    <li>major in Garments Technology</li>
                    <li>major in Mechanical Technology</li>
                  </ul>
                  <li>Master of Science in Industrial Technology</li>
                  <ul>
                    <li>major in Automotive Technology</li>
                    <li>major in Civil Technology</li>
                    <li>major in Drafting Technology</li>
                    <li>major in Electrical Technology</li>
                    <li>major in Electronics Technology</li>
                    <li>major in Food Technology</li>
                    <li>major in Garments Technology</li>
                    <li>major in Mechanical Technology</li>
                  </ul>
                <h3 id="transition-modal-description">School of Medicine</h3>
                  <li>Doctor of Medicine</li>
              </div>
            </Fade>
          </Modal>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openBal}
            onClose={handleCloseBal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openBal}>
              <div className={classes.paper}>
              <IconButton className={classes.closebutton} onClick={handleCloseBal} aria-label="close" color="primary">
                <CloseIcon />
              </IconButton>
                <h1 id="transition-modal-title">Balilihan Campus</h1>
                <h3 id="transition-modal-description">College of Technology and Allied Sciences</h3>
                  <li>Bachelor of Science in Information Technology</li>
                  <li>Bachelor of Science in Computer Science</li>
                  <li>Bachelor of Science in Electrical Technology</li>
                  <li>Bachelor of Science in Electronics Technology</li>
                  <li>Bachelor of Science in Industrial Technology</li>
                  <ul>
                    <li>major in Food Preparation and Service Management</li>
                    <li>major in Garments Technology</li>
                  </ul>
                <h3 id="transition-modal-description">College of Criminal Justice</h3>
                  <li>Bachelor of Science in Criminology</li>
              </div>
            </Fade>
          </Modal>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openBil}
            onClose={handleCloseBil}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openBil}>
              <div className={classes.paper}>
              <IconButton className={classes.closebutton} onClick={handleCloseBil} aria-label="close" color="primary">
                <CloseIcon />
              </IconButton>
                <h1 id="transition-modal-title">Bilar Campus</h1>
                <h3 id="transition-modal-description">College of Agriculture and Natural Sciences</h3>
                  <li>Bachelor of Science in Agriculture</li>
                  <li>Bachelor of Science in Agricultural and Biosystems Engineering</li>
                  <li>Bachelor of Science in Environmental Science</li>
                  <li>Bachelor of Science in Forestry</li>

                <h3 id="transition-modal-description">College of Teacher Education</h3>
                  <li>Bachelor in Elementary Education major in General Education</li>
                  <li>Bachelor in Secondary Education</li>
                  <li>Egnlish</li>
                  <li>Filipino</li>
                  <li>Mathematics</li>
                  <li>Bachelor in Technology and Livelihood Education</li>
                  <ul>
                    <li>major in Home Economics</li>
                    <li>major in Agri-Fishery Arts</li>
                  </ul>
                <h3 id="transition-modal-description">College of Technology and Allied Sciences</h3>
                  <li>Bachelor of Science in Entrepreneurship</li>
                  <li>Bachelor of Science in Computer Science</li>
                  <li>Bachelor of Science in Hospitality Management</li>
                  <li>Bachelor of Science in Office Administration</li>
                  <li>Bachelor of Science in Industrial Technology</li>
                  <ul>
                    <li>major in Automotive Technology</li>
                    <li>major in Food Preparationa and Service Management</li>
                  </ul>
                <h3 id="transition-modal-description">College of Advanced Studies</h3>
                  <li>Doctor of Philosophy major in Community Development</li>
                  <li>Master in Agriculture and Development</li>
                  <ul>
                    <li>major in Community Development</li>
                    <li>major in Agricultural Development</li>
                    <li>major in Agricultural Education</li>
                    <li>major in Rural Systems Management</li>
                  </ul>
                  <li>Master in Public Administration major Personnel Development</li>
                  <li>Master in Education major in Educational Management</li>
              </div>
            </Fade>
          </Modal>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openCal}
            onClose={handleCloseCal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openCal}>
              <div className={classes.paper}>
              <IconButton className={classes.closebutton} onClick={handleCloseCal} aria-label="close" color="primary">
                <CloseIcon />
              </IconButton>
                <h1 id="transition-modal-title">Calape Campus</h1>
                <h3 id="transition-modal-description">College of Teacher Education</h3>
                  <li>Bachelor in Elementary Education</li>
                  <li>Bachelor in Secondary Education</li>
                  <ul>
                    <li>major in English</li>
                    <li>major in Mathematics</li>
                  </ul>
                <h3 id="transition-modal-description">College of Technology and Allied Sciences</h3>
                  <li>Bachelor of Science in Computer Science</li>
                  <li>Bachelor of Science in Fisheries</li>
                  <li>Bachelor of Science in Industrial Technology</li>
                  <ul>
                    <li>major in Food Preparation and Service Management</li>
                    <li>major in Eletrical Technology</li>
                  </ul>
                  <li>Bachelor of Science in Midwifery</li>
              </div>
            </Fade>
          </Modal>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openCan}
            onClose={handleCloseCan}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openCan}>
              <div className={classes.paper}>
                <IconButton className={classes.closebutton} onClick={handleCloseCan} aria-label="close" color="primary">
                  <CloseIcon />
                </IconButton>
                <h1 id="transition-modal-title">Candijay Campus</h1>
                <h3 id="transition-modal-description">College of Fisheries and Marine Sciences</h3>
                  <li>Bachelor of Science in Fisheries</li>
                  <li>Bachelor of Science in Marine Biology</li>
                  <li>Bachelor of Science in Environmental Science major in Coastal Resource Management</li>

                <h3 id="transition-modal-description">College of Teacher Education</h3>
                <li>Bachelor in Elementary Education</li>
                <li>Bachelor in Secondary Education</li>
                <ul>
                  <li>major in English</li>
                  <li>major in Filipino</li>
                  <li>major in Science</li>
                  <li>major in Mathematics</li>
                </ul>

                <h3 id="transition-modal-description">College of Technology and Allied Sciences</h3>
                  <li>Bachelor of Science in Office Administration</li>
                  <li>Bachelor of Science in Computer Science</li>
                  <li>Bachelor of Science in Hospitality Management</li>

                <h3 id="transition-modal-description">College of Advanced Studies</h3>
                  <li>Master in Education major in Educational Management</li>
              </div>
            </Fade>
          </Modal>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openCla}
            onClose={handleCloseCla}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openCla}>
              <div className={classes.paper}>
              <IconButton className={classes.closebutton} onClick={handleCloseCla} aria-label="close" color="primary">
                <CloseIcon />
              </IconButton>
                <h1 id="transition-modal-title">Clarin Campus</h1>
                <h3 id="transition-modal-description">College of Teacher Education</h3>
                  <li>Bachelor in Elementary Education</li>
                  <li>Bachelor in Secondary Education major in Mathematics</li>
                  <li>Bachelor of Technology and Livelihood Education major in Home Economics</li>
                  <li>Bridging Course</li>

                <h3 id="transition-modal-description">College of Technology and Allied Sciences</h3>
                  <li>Bachelor of Science in Computer Science</li>
                  <li>Bachelor of Science in Hospitality Management</li>
                  <li>Bachelor of Science in Environmental Science major in Coastal Resource Management</li>
              </div>
            </Fade>
          </Modal>
        </div>
    </main>
  )

}
