export const getIncomeBracketVal = id => {
  if (getIncomeBracketVal===""){
    return "-"
  }
  let incomeStr = "";
  switch (parseInt(id)){
    case 1:
      incomeStr="below Php. 10,957 monthly income - Poor income cluster"
      break;
    case 2:
      incomeStr="between Php. 10,957-21,914 monthly income - Low-income cluster"
      break;
    case 3:
      incomeStr="between Php. 21,914-43,828 monthly income - Lower middle-income cluster"
      break;
    case 4:
      incomeStr="between Php. 43,828-76,699 monthly income - Middle middle-income cluster"
      break;
    case 5:
      incomeStr="between Php. 76,699-131,484 monthly income- Upper middle-income cluster"
      break;
    case 6:
      incomeStr="between Php. 131,484-219,140 monthly income - Upper-income cluster"
      break;
    case 7:
      incomeStr="above Php. 219,140 monthly income - Rich cluster"
      break;
    default:
      incomeStr="-"
      break;
  }
  return incomeStr;
}
