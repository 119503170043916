import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
}));

const Requirements = (props) => {
  const classes = useStyles();
  const { requirements, setRequirements } = props;
  const handleChange = (files) => {
    setRequirements(files);
  }
  return (
    <div>
      <Typography variant="body2" color="textSecondary" paragraph>
        A Certification that the applicant is currently enrolled in Senior High School duly signed by the Principal or by an authorized personnel.
        <br />
        Certified true copy of Form 137 in Grade 10 and Grade 11
        <br />
        Certificate of Good Moral Character
        <br />
        2x2 Picture, formal, recent, with a nametag and plain background
        <br />
        Informative Copy of TOR <em><small>(For transferees only)</small></em>
        <br />
        Certificate of General Weighted Average<em><small>(For transferees only)</small></em>
      </Typography>
      <DropzoneArea
        acceptedFiles={[
          'image/bmp',
          'image/jpg',
          'image/png',
          'image/jpeg',
          'application/pdf',
          'application/wps-office.doc',
          'application/wps-office.docx',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ]}
        initialFiles={requirements}
        dropzoneText="drag and drop files here or click to upload"
        filesLimit={5}
        maxFileSize={5000000}
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
        previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText="Selected files"
        onChange={handleChange}
      />
      <Typography variant="body2" color="textSecondary" paragraph><em><small>Note: Files needs to be in JPEG/PDF format.</small></em></Typography>
    </div>
  );
}

export default Requirements;
