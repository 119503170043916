import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Appbar from './components/Appbar';
import Apply from './components/Apply';
import Landing from './components/Landing';
import Programs from './components/Programs';
import Footer from './components/Footer';
const App = () => {
  return (
    <Router>
        <Appbar />
        <Container maxWidth="md">
          <Switch>
            <Route exact path="/" component={Landing} />
            {/* <Route path="/apply" component={Apply} /> */}
            <Route path="/apply" component={Apply} />
            <Route path="/programs" component={Programs} />
          </Switch>
        </Container>
        <Footer />
    </Router>
  );
}

export default App;
