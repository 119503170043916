import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { getIncomeBracketVal } from '../../utils/income';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Review = (props) => {
  const { applicationDetails, personalInfo, academicDetails, campuses, courses, provinces, towns } = props;
  const [campus, setCampus] = useState({});
  const [course1, setCourse1] = useState({});
  const [course2, setCourse2] = useState({});
  const [course3, setCourse3] = useState({});
  const [province, setProvince] = useState({});
  const [town, setTown] = useState({});
  useEffect(() => {
    setCampus(() => {
      if (!campuses || applicationDetails.campus === 0) {
        return {};
      }
      return campuses.find(campus => campus.id === applicationDetails.campus);
    })
    setCourse1(() => {
      if (!courses || applicationDetails.course1 === 0) {
        return {};
      }
      return courses.find(course => course.id === applicationDetails.course1);
    })
    setCourse2(() => {
      if (!courses || applicationDetails.course2 === 0) {
        return {};
      }
      return courses.find(course => course.id === applicationDetails.course2);
    })
    setCourse3(() => {
      if (!courses || applicationDetails.course3 === 0) {
        return {};
      }
      return courses.find(course => course.id === applicationDetails.course3);
    })
    setProvince(() => {
      if (!provinces || personalInfo.province_id === 0) {
        return {};
      }
      return provinces.find(province => province.id === personalInfo.province_id);
    })
    setTown(() => {
      if (!towns || personalInfo.area_id === 0) {
        return {};
      }
      return towns.find(town => town.id === personalInfo.area_id);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField label="Campus" value={campus.campus_name ? campus.campus_name : "-"} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField label="Application Type" value={applicationDetails.type === "" ? "-" : applicationDetails.type} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="First Priority Course" value={course1.course_name ? course1.course_name : "-"} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="Second Priority Course" value={course2.course_name ? course2.course_name : "-"} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="Third Priority Course" value={course3.course_name ? course3.course_name : "-"} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField label="Last Name" value={personalInfo.last_name === "" ? "-" : personalInfo.last_name} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField label="First Name" value={personalInfo.first_name === "" ? "-" : personalInfo.first_name} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField label="Middle Name" value={personalInfo.middle_name === "" ? "-" : personalInfo.middle_name} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField label="Suffix Name" value={personalInfo.suffix_name === "" ? "-" : personalInfo.suffix_name} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextField label="Gender" value={personalInfo.sex === "" ? "-" : personalInfo.sex} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextField label="Civil Status" value={personalInfo.civil_status === "" ? "-" : personalInfo.civil_status} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField label="Citizenship" value={personalInfo.citizenship === "" ? "-" : personalInfo.citizenship} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextField label="Date of Birth" value={personalInfo.dob === "" ? "-" : personalInfo.dob} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <TextField label="Place of Birth" value={personalInfo.pob === "" ? "-" : personalInfo.pob} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField label="House No./Street/Brgy." value={personalInfo.house_no_street_brgy === "" ? "-" : personalInfo.house_no_street_brgy} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField label="Municipality/Town" value={town.name ? town.name : "-"} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField label="Province" value={province.name ? province.name : "-"} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Alternate Address" value={personalInfo.alt_address === "" ? "-" : personalInfo.alt_address} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField label="Contact No." value={personalInfo.contact_no === "" ? "-" : personalInfo.contact_no} placeholder="09123456789" fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField label="Email" value={personalInfo.email === "" ? "-" : personalInfo.email} placeholder="example@email.com" fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Name of Father" value={personalInfo.name_father === "" ? "-" : personalInfo.name_father} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Name of Mother" value={personalInfo.name_mother === "" ? "-" : personalInfo.name_mother} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Parent's Address" value={personalInfo.address_parents === "" ? "-" : personalInfo.address_parents} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Income Bracket" value={getIncomeBracketVal(personalInfo.income_status)} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Do you identify yourself as a Person with Disability (PWD) or other has a chronic condition?" value={personalInfo.is_pwd === "" ? "-" : personalInfo.is_pwd} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Do you identify yourself as a member of indegenous people?" value={personalInfo.is_ip === "" ? "-" : personalInfo.is_ip} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Track Strand" value={academicDetails.track_strand === "" ? "-" : academicDetails.track_strand} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="LRN" value={academicDetails.lrn === "" ? "-" : academicDetails.lrn} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="General Average" value={academicDetails.general_average} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Last School Attended" value={academicDetails.last_school_attended === "" ? "-" : academicDetails.last_school_attended} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="School Address" value={academicDetails.last_school_attended_address === "" ? "-" : academicDetails.last_school_attended_address} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Date Graduated" value={academicDetails.date_graduated === "" ? "-" : academicDetails.date_graduated} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField label="Honor's Received" value={academicDetails.honors_received === "" ? "-" : academicDetails.honors_received} fullWidth inputProps={{ readOnly: true }} />
        </Grid>
        <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" paragraph>
                Please make sure that all data provided are correct, and all documents uploaded are complete
                before you submit your application. If you've made any errors, you may click "BACK" to correct them.
            </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default Review;
